<template>
  <div style="margin-top:84px">
    <!-- <goods-info :skc-code="skc_code" /> -->
    <goods-info :activity-no="activity_no" />
  </div>
</template>

<script>
import GoodsInfo from '../components/GoodsInfo.vue'
export default {
  components: { GoodsInfo },
  data() {
    return {
      // skc_code: '',
      activity_no: ''
    }
  },
  created() {
    // this.skc_code = this.$route.params.id
    this.activity_no = this.$route.params.id
    // console.log ('activity.vue activity_no: ' + this.activity_no)
  },
  mounted() {
  }
}
</script>
